import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-160e38e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "installments" }
const _hoisted_2 = {
  key: 0,
  class: "installments_wrapper"
}
const _hoisted_3 = { class: "installments_header header" }
const _hoisted_4 = { class: "header_title" }
const _hoisted_5 = { class: "header_text" }
const _hoisted_6 = { class: "header_line" }
const _hoisted_7 = {
  class: "header_link",
  href: "/landings/installment",
  target: "_blank"
}
const _hoisted_8 = { class: "header_text" }
const _hoisted_9 = { class: "header_line" }
const _hoisted_10 = {
  key: 0,
  class: "installments_section"
}
const _hoisted_11 = { class: "installments_caption" }
const _hoisted_12 = {
  key: 1,
  class: "installments_section"
}
const _hoisted_13 = { class: "installments_caption" }
const _hoisted_14 = {
  key: 1,
  class: "installments_section empty"
}
const _hoisted_15 = { class: "installments_caption" }
const _hoisted_16 = { class: "empty_text" }
const _hoisted_17 = {
  key: 1,
  class: "installments_preloader preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_installment = _resolveComponent("installment")!
  const _component_as_preloader = _resolveComponent("as-preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoaded && !_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('installments.title')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t('installments.descirption.text1')) + " ", 1),
                _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('installments.descirption.text2')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('installments.descirption.text3')), 1)
            ])
          ]),
          (_ctx.installments.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.installmentsIsActive.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t('installments.activeInstallments')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installmentsIsActive, (installment) => {
                        return (_openBlock(), _createBlock(_component_installment, {
                          key: `installments-is-active-${installment.id}`,
                          class: "installments_installment",
                          installment: installment
                        }, null, 8, ["installment"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.installmentsIsPaid.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('installments.paidInstallments')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installmentsIsPaid, (installment) => {
                        return (_openBlock(), _createBlock(_component_installment, {
                          key: `installments-is-paid-${installment.id}`,
                          class: "installments_installment",
                          installment: installment
                        }, null, 8, ["installment"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$t('installments.activeInstallments')), 1),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('installments.empty')), 1)
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode(_component_as_preloader, { class: "preloader_preloader" })
        ]))
  ]))
}