
import { computed, defineComponent, onMounted } from 'vue'

import Installment from './Installment.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'

export default defineComponent({
  name: 'CInstallments',
  components: {
    AsPreloader,
    Installment
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const isLoaded = computed(() => store.getters['installments/isLoaded'])
    const isEmpty = computed(() => store.getters['installments/isEmpty'])
    const installments = computed(() => store.getters['installments/installments'])
    const installmentsIsActive = computed(() => installments.value.filter(i => !i.isPaid))
    const installmentsIsPaid = computed(() => installments.value.filter(i => i.isPaid))

    function checkIsEmpty () {
      if (isEmpty.value) {
        router.push({ name: 'userSettings' })
      }
    }

    checkIsEmpty()

    onMounted(async () => {
      try {
        if (!isLoaded.value) {
          await store.dispatch('installments/fetchCachedInstallments')
          checkIsEmpty()
        }
      } catch (e) {
        Swal.fire({
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.installments'),
          icon: 'error'
        })
        sentry.captureException(e)
        Logger.error(e)
      }
    })

    return {
      isEmpty,
      isLoaded,
      installments,
      installmentsIsActive,
      installmentsIsPaid
    }
  }
})
