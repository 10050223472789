import CURRENCIES from './CURRENCIES.json'

export function currencyInfo () {
  function getCurrencyByDigitalCode (currencyCode: number) {
    return Object.values(CURRENCIES).find(c => c.code === currencyCode.toString())
  }

  function getCurrencyByAlphabeticCode (currencyCode: string) {
    const code = currencyCode.toUpperCase()
    if (!CURRENCIES[code]) return undefined
    return CURRENCIES[code]
  }

  function getCurrency (currencyCode: string | number) {
    if (!['string', 'number'].includes(typeof currencyCode)) return undefined

    if (typeof currencyCode === 'number') return getCurrencyByDigitalCode(currencyCode)

    return getCurrencyByAlphabeticCode(currencyCode)
  }

  return {
    getCurrency
  }
}
