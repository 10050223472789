
import { Installment } from '@web/types/Installment'
import { computed, defineComponent, PropType } from 'vue'

import { getNumberDecliner } from '@web/common/Utils'
import i18n from '@web/plugins/i18n'
import { currencyInfo } from '@ui-kit/util'
import Time from '@web/common/Time'

export default defineComponent({
  name: 'CInstallment',
  props: {
    installment: {
      type: Object as PropType<Installment>,
      required: true
    }
  },
  setup (props) {
    const currency = computed(() => currencyInfo().getCurrency(props.installment.currency)?.symbol || props.installment.currency)
    const numberOfPayments = computed(() => Math.max(props.installment.numberOfPayments || 0, props.installment.numberOfCompletedPayments || 0))
    const numberOfPaymentsIsLeft = computed(() => props.installment.isPaid ? 0 : numberOfPayments.value - props.installment.numberOfCompletedPayments)
    const amountIsLeft = computed(() => props.installment.isPaid ? 0 : numberOfPaymentsIsLeft.value * props.installment.recurrentAmount)
    const createdAtFromatted = computed(() => Time(props.installment.createdAt, 'DD.MM.YYYY HH:mm:ss').format('DD MMMM YYYY, HH:mm'))
    const nextPayDateFromatted = computed(() => {
      // if (props.installment.isCancelled && props.installment.canceledAt) {
      //   return Time(props.installment.canceledAt, 'DD.MM.YYYY HH:mm:ss').format('DD MMMM YYYY')
      // }
      if (props.installment.isPaid && props.installment.lastPaidAt) {
        return Time(props.installment.lastPaidAt, 'DD.MM.YYYY HH:mm:ss').format('DD MMMM YYYY')
      }
      return Time(props.installment.nextPayDate, 'YYYY-MM-DD').format('DD MMMM YYYY')
    })
    const nextPayText = computed(() => {
      // if (props.installment.isCancelled) {
      //   return i18n.global.t('installments.cancelled')
      // }
      if (props.installment.isPaid) {
        return i18n.global.t('installments.paid')
      }
      return i18n.global.t('installments.nextPay')
    })
    const paymentsLeftText = computed(() => getNumberDecliner({
      n: numberOfPaymentsIsLeft.value,
      single: i18n.global.t('installments.paymentsDecliner.one'),
      multiple: i18n.global.t('installments.paymentsDecliner.many'),
      multiple2: i18n.global.t('installments.paymentsDecliner.few')
    }))
    const paymentPeriodText = computed(() => i18n.global.t('installments.paymentPariod.next'))

    function amountFormatting (amount: number): string {
      return amount.toFixed(2)
    }

    return {
      currency,
      numberOfPayments,
      numberOfPaymentsIsLeft,
      amountIsLeft,
      createdAtFromatted,
      nextPayDateFromatted,
      nextPayText,
      paymentsLeftText,
      paymentPeriodText,
      amountFormatting
    }
  }
})
